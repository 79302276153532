import React from "react";
import { graphql, useStaticQuery } from "gatsby"

import { Layout, SEO } from '../../../components/structure';
import {
  Hero,
  Content,
  ExecutiveBio,
  Button,
  CallToAction
} from "../../../components/blocks";

import styled from "styled-components";
import { mq, brand, font } from "../../../styles"

import english from "../../../../content/translations/en-CA/about.json"
import french from "../../../../content/translations/fr-CA/about.json"



const ExecLeadershipTeam = () => {
  let lang = english;
  const data = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-ELT.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIconBlue: file(base: {eq: "arrow-right-blue.png"}) {
        ...ButtonIcon
      }
      execKenTile: file(base:{eq: "KenHarris.jpg"}){
        ...BioTileImage
      }
      execKenPopup: file(base:{eq: "KenHarris.jpg"}){
        ...BioPopupImage
      }
      execBrettTile: file(base:{eq: "BrettM.jpg"}){
        ...BioTileImage
      }
      execBrettPopup: file(base:{eq: "BrettM.jpg"}){
        ...BioPopupImage
      }
      execDanielleTile: file(base:{eq: "DanielleB.jpg"}){
        ...BioTileImage
      }
      execDaniellePopup: file(base:{eq: "DanielleB.jpg"}){
        ...BioPopupImage
      }
      execGeorgeTile: file(base:{eq: "GeorgeY.jpg"}){
        ...BioTileImage
      }
      execGeorgePopup: file(base:{eq: "GeorgeY.jpg"}){
        ...BioPopupImage
      }
      execSarahTile: file(base:{eq: "SarahB.jpg"}){
        ...BioTileImage
      }
      execSarahPopup: file(base:{eq: "SarahB.jpg"}){
        ...BioPopupImage
      }
      execErickTile: file(base:{eq: "ErickG.jpg"}){
        ...BioTileImage
      }
      execErickPopup: file(base:{eq: "ErickG.jpg"}){
        ...BioPopupImage
      }
      execJayTile: file(base:{eq: "JayM.jpg"}){
        ...BioTileImage
      }
      execJayPopup: file(base:{eq: "JayM.jpg"}){
        ...BioPopupImage
      }
      execJoseeTile: file(base:{eq: "JoseeK.jpg"}){
        ...BioTileImage
      }
      execJoseePopup: file(base:{eq: "JoseeK.jpg"}){
        ...BioPopupImage
      }
      
    }
  `);

  const ExecutiveContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 35px 70px;
    width: 95%;
    max-width: 1400px;
    margin: auto;
    background: #fff;
    padding: 100px 0;
    @media screen and ${mq.maxMd} {
      display: block;
      
    }
  `;

  const ExecutiveIntro = styled.div`
  
  ${ font.imports.sherika }

    width: 95%;
    max-width: 1400px;
    margin: 100px auto;
    @media screen and ${mq.maxMd} {
      text-align: center;
    }

    h4 {
      font-family: ${font.family.sherika};
      font-weight: 600;
      font-size: 48px;
      color: ${brand.colors.pg_blue};
      
    }

    h3 {
      font-family: ${font.family.sherika};
      font-weight:600;
      font-size: 32px;
      color: #fff;
    }
  `;

  return(
    <Layout>
    <SEO title={"Équipe de direction | Plusgrade"} 
          description={'Rencontrez notre équipe de direction - un groupe diversifié de personnes qui mènent la voie pour faire de Plusgrade le leader mondial des solutions de revenus accessoires.'}
          keywords={'leadership, ceo, executives'}
          />
    <Hero
      title={"Équipe de\ndirection"}
      subtitle={lang.hero.subtitle}
      image={ data['heroImg'] }
      imageStyle={{objectPosition: "center bottom"}}
      fullBG
      />
  <Content contained>
    <ExecutiveIntro>
      <h4>Rencontrez notre équipe de direction</h4>
      <h3>
      Quand vient le temps de concrétiser notre mission d’être le leader mondial des revenus de produits auxiliaires, cette équipe ouvre le chemin. Découvrez l’expérience diversifiée qu’ils apportent à Plusgrade.
      </h3>
    </ExecutiveIntro>
  </Content>
  
  <Content whiteBG>
    <ExecutiveContainer>
      <ExecutiveBio 
        name={"Ken Harris"}
        title={"Fondateur et PDG"}
        blurb={"Ken Harris est le fondateur et PDG de Plusgrade, une entreprise de logiciels qui est leader dans l’industrie mondiale du voyage et l’une des plus grandes entreprises de technologie privée du Canada. Sous la direction de Ken, Plusgrade a offert des milliards de dollars en nouvelles opportunités de revenus auxiliaires à plus de 200 entreprises de voyages, dans 60 pays différents. Il a également été honorée par le prestigieux prix Deloitte Technology Leadership en tant que leader mondial dans son secteur. En 2020, Ken a été nommé Top 40 Under 40 au Canada, une reconnaissance annuelle sur ses réalisations exceptionnelles."}
        profileIcon = {data['execKenTile']}
        profilePopup = {data['execKenPopup']}
        linkedin={'https://www.linkedin.com/in/kenharris/'}
        fr
      />

      <ExecutiveBio 
        name={"Jay Malowney"}
        title={"Président, Points"}
        blurb={"Jay supervise tous les aspects de la stratégie, des performances, de la croissance et des partenariats de l'unité commerciale de Points. Auparavant, il était Chef Commercial (CCO) chez Points, où il gérait la croissance commerciale, les performances, la gestion des relations, le marketing, les équipes de croissance et les partenariats stratégiques. Il apporte à l'équipe ses vastes connaissances et son expérience du secteur de la fidélisation, en veillant à ce que l'organisation continue à favoriser l'innovation et le progrès. Avant de rejoindre Points, Jay a occupé des postes à responsabilité chez LoyaltyOne, où il a notamment dirigé l'expansion réussie de l'entreprise sur le marché brésilien avec le programme de fidélisation de la coalition Dotz."}
        profileIcon = {data['execJayTile']}
        profilePopup = {data['execJayPopup']}
        linkedin={'https://www.linkedin.com/in/jay-malowney-3302693/'}
        fr
      />
      <ExecutiveBio 
        name={"Brett Mellon"}
        title={"Président, Solutions"}
        blurb={"Brett est un ingénieur professionnel accompli avec plus de 30 ans d'expérience dans 5 industries différentes, spécialement dans l'industrie technologique où il a occupé des postes opérationnels pendant les 10 dernières années. Il est réputé pour sa capacité à résoudre les problèmes et sa grande compréhension des opérations, ayant travaillé à tous les niveaux de gestion. Brett a également consacré plus de 15 ans au secteur automobile, démontrant sa capacité à diriger et à inspirer des équipes tout en fournissant un service exceptionnel aux autres."}
        profileIcon = {data['execBrettTile']}
        profilePopup = {data['execBrettPopup']}
        linkedin={'https://www.linkedin.com/in/brett-mellon-99418615/'}
        fr
      />
      <ExecutiveBio 
        name={"Sarah Bettencourt"}
        title={"Directrice du personnel"}
        blurb={"Sarah est chargée de superviser tous les aspects du personnel et de la culture de l'organisation, y compris la gestion de la performance, la planification de la relève, les perspectives commerciales et la stratégie. Sarah est titulaire d'un master en efficacité et conception organisationnelles et a reçu un certain nombre de récompenses (Female Business Leader of the Year et Female Executive Leader Award en 2019)."}
        profileIcon = {data['execSarahTile']}
        profilePopup = {data['execSarahPopup']}
        linkedin={'https://www.linkedin.com/in/sarah-bettencourt-mhrm-2bb5754/'}
        fr
      />
      <ExecutiveBio 
        name={"Danielle Brown"}
        title={"Directrice du marketing"}
        blurb={"Danielle est chargée de superviser les équipes de science des données et l'analyse, de marque mondiale et de contenu, ainsi que le marketing des partenaires, des produits et de la performance. Avec plus de 20 ans d'expérience dans le domaine, elle a mis en place des équipes de marketing et d'opérations commerciales réussies pour des organisations nationales et mondiales. Danielle est une experte en gestion du changement, une négociatrice et une conseillère qualifiée, capable d'établir un consensus et de faire progresser les entreprises. Sa carrière diversifiée couvre les domaines du divertissement, du sans fil, de la fidélisation, de la vente au détail, du commerce électronique et de la technologie, à la fois sur les marchés B2B et B2C."}
        profileIcon = {data['execDanielleTile']}
        profilePopup = {data['execDaniellePopup']}
        linkedin={'https://www.linkedin.com/in/danielle-brown-7aaa3427/'}
        fr
      />
      <ExecutiveBio 
        name={"Erick Georgiou"}
        title={"Directeur financier"}
        blurb={"Erick est responsable de la santé financière globale de l'entreprise. Erick était précédemment le Chef des Finances de Points (maintenant une société Plusgrade) et a fait partie intégrante de la croissance de la société en tant que société publique à double cotation depuis qu'il l'a rejointe en 2008. Au cours de son mandat chez Points, il a dirigé un large éventail de fonctions financières, notamment la planification et l'analyse des activités, la comptabilité, les relations avec les investisseurs, la trésorerie et la fiscalité, les paiements et la gestion des risques."}
        profileIcon = {data['execErickTile']}
        profilePopup = {data['execErickPopup']}
        linkedin={'https://www.linkedin.com/in/erick-georgiou-cpa-ca-8591609/'}
        fr
      />
      <ExecutiveBio 
      name={"Josée Kouri"}
      title={"Vice-présidente principale et Conseillère générale"}
      blurb={"Josée travaille en étroite collaboration avec la direction générale et le conseil d'administration, et fournit des conseils en matière de négociations commerciales, de questions réglementaires, de litiges, de propriété intellectuelle, de gouvernance d'entreprise et de conformité. Avant de se joindre à Plusgrade, Josée était première vice-présidente, Affaires juridiques et secrétaire générale de Dollarama, une société cotée à la Bourse de Toronto qui exploite des magasins de détail au Canada et détient une participation majoritaire dans la chaîne de magasins Dollarcity en Amérique latine. Avant de se joindre à Dollarama, Josée a travaillé au sein du groupe de droit des affaires d'un important cabinet d'avocats canadien."}
      profileIcon = {data['execJoseeTile']}
      profilePopup = {data['execJoseePopup']}
      linkedin={'https://www.linkedin.com/in/jos%C3%A9e-kouri-7ab41115/'}
      fr
    />
      <ExecutiveBio 
        name={"George Yao"}
        title={"Directeur de la stratégie"}
        blurb={"George apporte à l'entreprise plus de 17 ans d'expérience en tant que conseiller stratégique et financier. Au cours de sa carrière chez Morgan Stanley et TD Securities, George a occupé le poste de directeur général et a dirigé la couverture des services bancaires d'investissement pour les technologies et les sponsors financiers au Canada. Il a mené avec succès des opérations d'introduction en bourse et de fusions-acquisitions de haut niveau et transformationnelles. George est titulaire d'un MBA de l'Université de Toronto et d'une maîtrise en sciences informatiques de l'Université de l'Ouest, où il a obtenu un diplôme avec mentions honorables pour les deux diplômes."}
        profileIcon = {data['execGeorgeTile']}
        profilePopup = {data['execGeorgePopup']}
        linkedin={'https://www.linkedin.com/in/georgeyao/'}
        fr
      />

      <div style={{visibility: "hiddn", width: "250px"}}>

      </div>

    </ExecutiveContainer>

    <CallToAction responsiveButton
      title={'Joignez-vous à l’équipe'}
      subtitle={'Nous sommes toujours à la recherche de personnes motivées et créatives pour rejoindre notre équipe.'}
      > 
      <Button destination={'/fr/carrieres/'} label={'Voir les postes vacants'} icon={data['rightArrowIconBlue']} white blueText pill />
    </CallToAction>

  </Content>
  </Layout>
  )
};


export default ExecLeadershipTeam;